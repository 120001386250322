function NoPage() {
  return (
    <div className="App">
      <header className="App-header">
        <p className="text-white text-center my-10">yeah so whatever you thought this was does not exist...</p>
        <p className="text-center"><a className="text-white text-center underline" href="/">go back home</a></p>
      </header>
    </div>
  )
}

export default NoPage;